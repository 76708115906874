import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Device,
  DeviceState,
  DevicesState,
  HierarchyFoldersState,
  HierarchyFolder,
  HierarchyFolderState,
  HierarchyFolderType,
  HierarchyFolderTypesState,
  Threshold,
  ThresholdsState,
  InitialEntityTagsState,
} from 'app/types';

export const initialDevicesState: DevicesState = { devices: [], searchQuery: '', hasFetched: false };
export const initialHierarchyFoldersState: HierarchyFoldersState = {
  hierarchyFolders: [],
  hierarchyFoldersMap: {},
  searchQuery: '',
  hasFetched: false,
};
export const initialHierarchyFolderTypesState: HierarchyFolderTypesState = {
  hierarchyFolderTypes: [],
};
export const initialThresholdsState: ThresholdsState = { thresholds: [] };

export const initialEntityTagsState: InitialEntityTagsState = {
  hierarchyTagOptions: [],
  initialTags: [],
};

const devicesSlice = createSlice({
  name: 'devices',
  initialState: initialDevicesState,
  reducers: {
    devicesLoaded: (state, action: PayloadAction<Device[]>): DevicesState => {
      return { ...state, hasFetched: true, devices: action.payload };
    },
    setDeviceSearchQuery: (state, action: PayloadAction<string>): DevicesState => {
      return { ...state, searchQuery: action.payload };
    },
  },
});

const hierarchyFoldersSlice = createSlice({
  name: 'hierarchyFolders',
  initialState: initialHierarchyFoldersState,
  reducers: {
    hierarchyFoldersLoaded: (state, action: PayloadAction<HierarchyFolder[]>): HierarchyFoldersState => {
      return {
        ...state,
        hasFetched: true,
        hierarchyFolders: action.payload,
        hierarchyFoldersMap: action.payload.reduce((p: any, c) => (p[c.id] = c) && p, {}),
      };
    },
    setFolderSearchQuery: (state, action: PayloadAction<string>): HierarchyFoldersState => {
      return { ...state, searchQuery: action.payload };
    },
  },
});

const hierarchyFolderTypesSlice = createSlice({
  name: 'hierarchyFolderTypes',
  initialState: initialHierarchyFolderTypesState,
  reducers: {
    hierarchyFolderTypesLoaded: (state, action: PayloadAction<HierarchyFolderType[]>): HierarchyFolderTypesState => {
      return {
        ...state,
        hierarchyFolderTypes: action.payload,
      };
    },
  },
});

const entityInitialTagsSlice = createSlice({
  name: 'hierarchyTags',
  initialState: initialEntityTagsState,
  reducers: {
    entityInitialTagsLoaded: (state, action: PayloadAction<InitialEntityTagsState>): InitialEntityTagsState => {
      return {
        ...state,
        hierarchyTagOptions: action.payload.hierarchyTagOptions,
        initialTags: action.payload.initialTags,
      };
    },
  },
});

export const { devicesLoaded, setDeviceSearchQuery } = devicesSlice.actions;
export const { hierarchyFoldersLoaded, setFolderSearchQuery } = hierarchyFoldersSlice.actions;
export const { hierarchyFolderTypesLoaded } = hierarchyFolderTypesSlice.actions;
export const { entityInitialTagsLoaded } = entityInitialTagsSlice.actions;

export const devicesReducer = devicesSlice.reducer;
export const hierarchyFoldersReducer = hierarchyFoldersSlice.reducer;
export const HierarchyFolderTypesReducer = hierarchyFolderTypesSlice.reducer;
export const EntityInitialTagsReducer = entityInitialTagsSlice.reducer;

export const initialDeviceState: DeviceState = {
  device: {} as Device,
};

export const initialHierarchyFolderState: HierarchyFolderState = {
  hierarchyFolder: {} as HierarchyFolder,
  hierarchyFolderParent: {} as HierarchyFolder,
  hierarchyFolderChildren: [] as HierarchyFolder[],
};

const deviceSlice = createSlice({
  name: 'device',
  initialState: initialDeviceState,
  reducers: {
    deviceLoaded: (state, action: PayloadAction<Device>): DeviceState => {
      return { ...state, device: action.payload };
    },
  },
});

const hierarchyFolderSlice = createSlice({
  name: 'hierarchyFolder',
  initialState: initialHierarchyFolderState,
  reducers: {
    hierarchyFolderLoaded: (state, action: PayloadAction<HierarchyFolder>): HierarchyFolderState => {
      return { ...state, hierarchyFolder: action.payload };
    },
    hierarchyFolderParentLoaded: (state, action: PayloadAction<HierarchyFolder>): HierarchyFolderState => {
      return { ...state, hierarchyFolderParent: action.payload };
    },
    hierarchyFolderChildrenLoaded: (state, action: PayloadAction<HierarchyFolder[]>): HierarchyFolderState => {
      return { ...state, hierarchyFolderChildren: action.payload };
    },
  },
});

export const { deviceLoaded } = deviceSlice.actions;
export const { hierarchyFolderLoaded, hierarchyFolderParentLoaded, hierarchyFolderChildrenLoaded } = hierarchyFolderSlice.actions;

export const deviceReducer = deviceSlice.reducer;
export const hierarchyFolderReducer = hierarchyFolderSlice.reducer;

const thresholdSlice = createSlice(
  {
    name: 'thresholds',
    initialState: initialThresholdsState,
    reducers: {
      thresholdsLoaded: (state, action: PayloadAction<Threshold[]>): ThresholdsState => {
        return { ...state, thresholds: action.payload };
      },
    },
  });

export const { thresholdsLoaded } = thresholdSlice.actions;

export const deviceThresholdsReducer = thresholdSlice.reducer;

export default {
  devices: devicesReducer,
  hierarchyFolders: hierarchyFoldersReducer,
  hierarchyFolderTypes: HierarchyFolderTypesReducer,
  device: deviceReducer,
  hierarchyFolder: hierarchyFolderReducer,
  thresholds: deviceThresholdsReducer,
  hierarchyTagOptions: EntityInitialTagsReducer,
};
