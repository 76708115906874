import React, { useState } from 'react';

import { ConfirmButton, RadioButtonGroup, Icon } from '@grafana/ui';
import { contextSrv } from 'app/core/core';
import { AccessControlAction } from 'app/types';

// RG
interface Props {
  isGrafanaAdmin: boolean;
  isManufacturer: boolean;
  isReseller: boolean;

  onPermissionsChange: (isGrafanaAdmin: boolean, isReseller: boolean, isManufacturer: boolean) => void;
}

const adminOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export function UserPermissions({ isGrafanaAdmin, isManufacturer, isReseller, onPermissionsChange }: Props) {
  const [isAdminEditing, setIsAdminEditing] = useState(false);
  const [isManufacturerEditing, setIsManufacturerEditing] = useState(false);
  const [isResellerEditing, setIsResellerEditing] = useState(false);
  const [currentAdminOption, setCurrentAdminOption] = useState(isGrafanaAdmin);
  const [currentManufacturerOption, setCurrentManufacturerOption] = useState(isManufacturer);
  const [currentResellerOption, setCurrentResellerOption] = useState(isReseller);

  const onChangeAdminClick = () => setIsAdminEditing(true);
  const onChangeManufacturerClick = () => setIsManufacturerEditing(true);
  const onChangeResellerClick = () => setIsResellerEditing(true);

  const onCancelAdminClick = () => {
    setIsAdminEditing(false);
    setCurrentAdminOption(isGrafanaAdmin);
  };
  const onCancelManufacturerClick = () => {
    setIsManufacturerEditing(false);
    setCurrentManufacturerOption(isManufacturer);
  };
  const onCancelResellerClick = () => {
    setIsResellerEditing(false);
    setCurrentResellerOption(isReseller);
  };

  const onGrafanaAdminChange = () => {
    const newIsGrafanaAdmin = currentAdminOption;
    const newIsReseller = newIsGrafanaAdmin && currentResellerOption;
    onPermissionsChange(newIsGrafanaAdmin, newIsReseller, isManufacturer);
  };

  const onManufacturerChange = () => {
    onPermissionsChange(isGrafanaAdmin, isReseller, currentManufacturerOption);
  };

  const onResellerChange = () => {
    const newIsReseller = currentResellerOption;
    const newIsGrafanaAdmin = newIsReseller || currentAdminOption;
    onPermissionsChange(newIsGrafanaAdmin, newIsReseller, isManufacturer);
  };

  // const handleGrafanaAdminChange = () => onPermissionsChange(currentAdminOption);

  const canChangePermissions = contextSrv.hasPermission(AccessControlAction.UsersPermissionsUpdate);

  return (
    <>
      <h3 className="page-heading">Permissions</h3>
      <div className="gf-form-group">
        <div className="gf-form">
          <table className="filter-table form-inline">
            <tbody>
              <tr>
                <td className="width-16">RadGreen Admin</td>
                {isAdminEditing ? (
                  <td colSpan={2}>
                    <RadioButtonGroup
                      options={adminOptions}
                      value={currentAdminOption}
                      onChange={setCurrentAdminOption}
                      autoFocus
                    />
                  </td>
                ) : (
                  <td colSpan={2}>
                    {isGrafanaAdmin ? (
                      <>
                        <Icon name="shield" /> Yes
                      </>
                    ) : (
                      <>No</>
                    )}
                  </td>
                )}
                <td>
                  {canChangePermissions && (
                    <ConfirmButton
                      onClick={onChangeAdminClick}
                      onConfirm={onGrafanaAdminChange}
                      onCancel={onCancelAdminClick}
                      confirmText="Change"
                    >
                      Change
                    </ConfirmButton>
                  )}
                </td>
              </tr>
              <tr>
                <td className="width-16">RadGreen ReSeller</td>
                {isResellerEditing ? (
                  <td colSpan={2}>
                    <RadioButtonGroup
                      options={adminOptions}
                      value={currentResellerOption}
                      onChange={setCurrentResellerOption}
                      autoFocus
                    />
                  </td>
                ) : (
                  <td colSpan={2}>
                    {isReseller ? (
                      <>
                        <Icon name="shield" /> Yes
                      </>
                    ) : (
                      <>No</>
                    )}
                  </td>
                )}
                <td>
                  {canChangePermissions && (
                    <ConfirmButton
                      onClick={onChangeResellerClick}
                      onConfirm={onResellerChange}
                      onCancel={onCancelResellerClick}
                      confirmText="Change"
                    >
                      Change
                    </ConfirmButton>
                  )}
                </td>
              </tr>
              <tr>
                <td className="width-16">RadGreen Manufacturer</td>
                {isManufacturerEditing ? (
                  <td colSpan={2}>
                    <RadioButtonGroup
                      options={adminOptions}
                      value={currentManufacturerOption}
                      onChange={setCurrentManufacturerOption}
                      autoFocus
                    />
                  </td>
                ) : (
                  <td colSpan={2}>
                    {isManufacturer ? (
                      <>
                        <Icon name="shield" /> Yes
                      </>
                    ) : (
                      <>No</>
                    )}
                  </td>
                )}
                <td>
                  {canChangePermissions && (
                    <ConfirmButton
                      onClick={onChangeManufacturerClick}
                      onConfirm={onManufacturerChange}
                      onCancel={onCancelManufacturerClick}
                      confirmText="Change"
                    >
                      Change
                    </ConfirmButton>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
