import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AnnotationEvent, DataSourceApi, AnnotationQuery, DataQuery/*, LoadingState*/ } from '@grafana/data';
import { toDataQueryResponse } from '@grafana/runtime';
import { standardAnnotationSupport } from 'app/features/annotations/standardAnnotationSupport';

import { executeAnnotationQuery } from '../../../annotations/executeAnnotationQuery';
import { PanelModel } from '../../../dashboard/state';

import { AnnotationQueryRunner, AnnotationQueryRunnerOptions } from './types';
import { handleAnnotationQueryRunnerError, translateQueryResult } from './utils';

export class AnnotationsQueryRunner implements AnnotationQueryRunner {
  canRun(datasource?: DataSourceApi): boolean {
    if (!datasource) {
      return false;
    }

    return Boolean(!datasource.annotationQuery || datasource.annotations);
  }

  run({ annotation, datasource, dashboard, range }: AnnotationQueryRunnerOptions): Observable<AnnotationEvent[]> {
    if (!this.canRun(datasource)) {
      return of([]);
    }

    const panel: PanelModel = {} as unknown as PanelModel; // deliberate setting panel to empty object because executeAnnotationQuery shouldn't depend on panelModel

    return executeAnnotationQuery({ dashboard, range, panel }, datasource!, annotation).pipe(
      map((result) => {
        return result.events ?? [];
      }),
      catchError(handleAnnotationQueryRunnerError)
    );
  }

  // RG
  loadQueryResult(annotation: AnnotationQuery): Observable<AnnotationEvent[]> {
       
    const processor = {
      ...standardAnnotationSupport,
    };

    let queries: DataQuery[] = [];

    let results = annotation.queryResult.results;
    const keys = Object.keys(results);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      queries.push({refId: key});
    }
    const rsp = toDataQueryResponse({data: annotation.queryResult}, queries); 
    return processor.processEvents!(annotation, rsp.data).pipe(map((events) => ({ rsp, events }))).pipe(
      map((result) => {
        return translateQueryResult(annotation, result.events ?? []);
      }),
      catchError(handleAnnotationQueryRunnerError)
    );

  }

  
}
