import { css } from '@emotion/css';
import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import { Modal, Input, useStyles2 } from '@grafana/ui';

import ChatArea, { ChatMessage } from '../ChatArea/ChatArea';

export interface KnowledgeBaseModalProps {
    onDismiss: () => void;
}

const PREDEFINED_QUESTIONS = ["What is CO2?", "What is PM2.5?", "What is the indoor environmental regulation in New York?"]

export const KnowledgeBaseModal = ({ onDismiss }: KnowledgeBaseModalProps): JSX.Element => {
    const styles = useStyles2(getStyles);

    const [question, setQuestion] = useState("");
    const [activeQuestion, setActiveQuestion] = useState<string | null>(null);
    const [messages, setMessages] = useState([] as ChatMessage[]);

    const ask = useCallback((q: string) => {
        setMessages((messages: ChatMessage[]) => [
            ...messages,
            { text: q, fromMe: true, timestamp: new Date() }
        ])
        setActiveQuestion(q);
    }, [setMessages, setActiveQuestion]);

    function handleModalFormSubmit (e: any) {
        e.preventDefault();
        ask(question);
        setQuestion("");
    };

    useEffect(() => {
        if (activeQuestion) {
            getBackendSrv()
                .get(`/api/knowledge-base?q=${activeQuestion}`)
                .then((answer: string) => {
                    setMessages((messages: ChatMessage[]) => [
                        ...messages,
                        { text: answer, fromMe: false, timestamp: new Date() }
                    ])
                    setActiveQuestion(null)
                })
                .catch((error: any) => {
                    setMessages((messages: ChatMessage[]) => [
                        ...messages,
                        { text: "An error has occured, please try again later", fromMe: false, error: true, timestamp: new Date() }
                    ])
                })
        }
    }, [activeQuestion, setMessages])

    useEffect(() => {
        ask("What is tVOC?");
    }, [ask])

    return (
      <Modal title="Knowledge Base" isOpen onDismiss={onDismiss} onClickBackdrop={onDismiss}>
        <ChatArea messages={messages} typing={!!activeQuestion} />
        <form className={`entity-form ${styles.inputWrapper}`} onSubmit={handleModalFormSubmit}>
            <Input className={styles.input} value={question} placeholder="Write any question..." onChange={(event: ChangeEvent<HTMLInputElement>) => { setQuestion(event.target.value); }}/>
        </form>
        <div className={styles.examples}>
            Examples: {PREDEFINED_QUESTIONS.map((q: string, index: number) =>
                <a onClick={(e) => ask(q)} key={`predef-${index}`}>{q}</a>
            )}
        </div>
      </Modal>
    );
  };
  
  function getStyles(theme: GrafanaTheme2) {
    return {
      examples: css`
        padding: 0 5px;
        margin-top: 8px;
        overflow-x: auto;
        white-space: nowrap;
        color: ${theme.colors.text.secondary};

        a {
            margin-right: ${theme.spacing(1)};
            text-decoration: underline;

            &:hover {
                color: ${theme.colors.text.link};
            }
        }
      `,
      inputWrapper: css`
        box-sizing: border-box;
        flex-shrink: 0;
        display: flex;
        align-items: center;
      `,
      input: css`
        height: 42px;
        border: none;
        background-image: none;
        background-color: white;
        padding: 0.5rem 1rem;
        border-radius: 0.225rem;
        flex-grow: 2;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1), 0rem 1rem 1rem -1rem rgba(0, 0, 0, 0.2);
        font-family: Red hat Display, sans-serif;
        font-weight: 400;
        letter-spacing: 0.025em;

        & input {
            border: none;
            outline: none;
        }

        .theme-dark & {
            background-color: rgb(20,23,26);
        }
      `,
    };
  }
