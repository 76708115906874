import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SerialNumber, SerialNumberState, SerialNumbersState } from 'app/types';

export const initialSerialNumbersState: SerialNumbersState = { serialNumbers: [], searchQuery: '', hasFetched: false };

const serialNumbersSlice = createSlice({
  name: 'serialNumbers',
  initialState: initialSerialNumbersState,
  reducers: {
    serialNumbersLoaded: (state, action: PayloadAction<SerialNumber[]>): SerialNumbersState => {
      return { ...state, hasFetched: true, serialNumbers: action.payload };
    },
    setSearchQuery: (state, action: PayloadAction<string>): SerialNumbersState => {
      return { ...state, searchQuery: action.payload };
    },
  },
});

export const { serialNumbersLoaded, setSearchQuery } = serialNumbersSlice.actions;

export const serialNumbersReducer = serialNumbersSlice.reducer;

export const initialSerialNumberState: SerialNumberState = {
  serialNumber: {} as SerialNumber,
};

const serialNumberSlice = createSlice({
  name: 'serialNumber',
  initialState: initialSerialNumberState,
  reducers: {
    serialNumberLoaded: (state, action: PayloadAction<SerialNumber>): SerialNumberState => {
      return { ...state, serialNumber: action.payload };
    },
  },
});

export const { serialNumberLoaded } = serialNumberSlice.actions;

export const serialNumberReducer = serialNumberSlice.reducer;

export default {
  serialNumbers: serialNumbersReducer,
  serialNumber: serialNumberReducer,
};
