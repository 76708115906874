import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Switch, SwitchState, SwitchesState } from 'app/types';

export const initialSwitchesState: SwitchesState = { switches: [], searchQuery: '', hasFetched: false };

const switchesSlice = createSlice({
  name: 'switches',
  initialState: initialSwitchesState,
  reducers: {
    switchesLoaded: (state, action: PayloadAction<Switch[]>): SwitchesState => {
      return { ...state, hasFetched: true, switches: action.payload };
    },
    setSearchQuery: (state, action: PayloadAction<string>): SwitchesState => {
      return { ...state, searchQuery: action.payload };
    },
  },
});

export const { switchesLoaded, setSearchQuery } = switchesSlice.actions;

export const switchesReducer = switchesSlice.reducer;

export const initialSwitchState: SwitchState = {
  switch: {} as Switch,
};

const switcheslice = createSlice({
  name: 'switch',
  initialState: initialSwitchState,
  reducers: {
    switchLoaded: (state, action: PayloadAction<Switch>): SwitchState => {
      return { ...state, switch: action.payload };
    },
  },
});

export const { switchLoaded } = switcheslice.actions;

export const switchReducer = switcheslice.reducer;

export default {
  switches: switchesReducer,
  switch: switchReducer,
};
