import React, { useEffect, useRef } from 'react';
import './ChatArea.css';

export interface ChatMessage {
	text: string;
	fromMe: boolean;
	timestamp?: Date;
	error?: boolean;
}

export interface Props {
	messages: ChatMessage[];
	typing?: boolean;
}

const ChatArea = ({ messages, typing }: Props) => {
	const scrollRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// Scroll to the bottom when the component updates
		if (scrollRef?.current) {
			scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
		}
	});

	let finalMessages = [...messages];
	if (typing) {
		finalMessages.push({ text: "", fromMe: false });
	}

	return (
		<div className="chat-area">
			{finalMessages.map((message, index) => {
				const lastMessage = index === messages.length;
				return lastMessage && typing ? <div
					key={index}
					className={`message from-other`}
				>
					<div><span className="typing typing-1"/><span className="typing typing-2"/><span className="typing typing-3"/></div>
				</div> : <div
					key={index}
					className={`message ${message.fromMe ? 'from-me' : 'from-other'} ${message.error ? 'error' : ''}`}
				>
					<div className="message-content">{message.text}</div>
					<div className="message-timestamp">{message.timestamp?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
				</div>
			})}
			<div ref={scrollRef} />
		</div>
	);
};

export default ChatArea;
