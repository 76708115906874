import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { NavModel } from '@grafana/data';
import { Form, Button, DeleteButton, VerticalGroup } from '@grafana/ui';
import { SlideDown } from 'app/core/components/Animations/SlideDown';
import { CloseButton } from 'app/core/components/CloseButton/CloseButton';
import EmptyListCTA from 'app/core/components/EmptyListCTA/EmptyListCTA';
import { Page } from 'app/core/components/PageNew/Page';
//import { contextSrv } from 'app/core/core';
import { OrgPicker, OrgSelectItem } from 'app/core/components/Select/OrgPicker';
import { HorizontalGroup } from 'app/features/plugins/admin/components/HorizontalGroup';
import { DashboardShortcut, StoreState } from 'app/types';


import { DashboardModel } from '../../state/DashboardModel';
import {
  getDashboardShortcuts,
  createDashboardShortcut,
  removeDashboardShortcut,
} from '../../state/actions';
import { ListNewButton } from '../DashboardSettings/ListNewButton';

//import { SettingsPageProps } from '../DashboardSettings/types';


const mapStateToProps = (state: StoreState) => ({
  shortcuts: state.dashboard.shortcuts,
});

const mapDispatchToProps = {
  getDashboardShortcuts,
  createDashboardShortcut,
  removeDashboardShortcut,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = {
  dashboard: DashboardModel;
  sectionNav: NavModel;
  shortcuts: DashboardShortcut[];
} & ConnectedProps<typeof connector>;

export interface State {
  isAdding: boolean;
  selectedOrgId: number;
}

export class DashboardShortcutsUnconnected extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isAdding: false,
      selectedOrgId: -1
    };
  }

  componentDidMount() {
    this.props.getDashboardShortcuts(this.props.dashboard.uid);
  }

  onOpenAddShortcut = () => {
    this.setState({ isAdding: true });
  };

  onRemoveItem = (orgId: number) => {
    this.props.removeDashboardShortcut(this.props.dashboard.uid, orgId);
  };

  onOrgSelect = (org: OrgSelectItem) => {
    this.setState({ selectedOrgId: org.value?.id || -1 });
  };

  onAddShortcut = (orgId: number) => {
    this.props.createDashboardShortcut(this.props.dashboard.uid, orgId);
    this.setState({ isAdding: false, selectedOrgId: -1 });
  };

  onCancelAddShortcut = () => {
    this.setState({ isAdding: false, selectedOrgId: -1 });
  };

  render() {
    const { shortcuts, sectionNav } = this.props;
    const { isAdding } = this.state;

    const showEmptyListCTA = shortcuts.length === 0;

    return (
      <Page navModel={sectionNav}>
        <SlideDown in={isAdding}>
          <div className="cta-form">
            <CloseButton onClick={() => { this.onCancelAddShortcut() }} />
            <h5>Add Shortcut to this dashboard in Organization:</h5>
            <Form maxWidth="none" onSubmit={() => { this.onAddShortcut(this.state.selectedOrgId) }}>
              {() => (
                <HorizontalGroup>
                  <OrgPicker onSelected={this.onOrgSelect} excludeOrgs={[]} autoFocus />
                  <Button variant="primary" onClick={() => { this.onAddShortcut(this.state.selectedOrgId) }}>
                    Create Shortcut
                  </Button>
                </HorizontalGroup>
              )}
            </Form>
          </div>
        </SlideDown>
        <VerticalGroup>
          {shortcuts.length > 0 && (
            <table className="filter-table filter-table--hover">
              <thead>
                <tr>
                  <th>Organization</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {shortcuts.map((shortcut, idx) => (
                  <tr key={`${shortcut.orgId}-${idx}`}>
                    <td>
                      {shortcut.orgName}
                    </td>
                    <td style={{ width: '1%' }}>
                      <DeleteButton
                        size="sm"
                        onConfirm={() => this.onRemoveItem(shortcut.orgId)}
                        aria-label={`Delete item with title "${shortcut.orgName}"`}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {showEmptyListCTA && (
            <EmptyListCTA
              onClick={this.onOpenAddShortcut}
              title="There are no shortcuts created yet"
              buttonIcon="comment-alt"
              buttonTitle="Add a shortcut"
            />
          )}
          {!showEmptyListCTA && <ListNewButton onClick={this.onOpenAddShortcut}>New shortcut</ListNewButton>}
        </VerticalGroup>
      </Page>
    );
  }
}

export const DashboardShortcuts = connector(DashboardShortcutsUnconnected);
