import { css } from '@emotion/css';
import moment, { Moment } from 'moment-timezone';
import React, { useState, useEffect, useCallback } from 'react';

import { PanelProps, dateMath, SelectableValue, DateTime } from '@grafana/data';
import { getLocationSrv } from '@grafana/runtime';
import { RadioButtonGroup, Select } from '@grafana/ui';
import { config } from 'app/core/config';
import { getTimeSrv } from 'app/features/dashboard/services/TimeSrv';

import { TimeSeriesPanel } from '../timeseries/TimeSeriesPanel';
import { TimeSeriesOptions } from '../timeseries/types';

interface TimeSeriesPanelProps extends PanelProps<TimeSeriesOptions> { }

export const Rggraph: React.FC<TimeSeriesPanelProps> = (props) => {
  const { data, timeRange, replaceVariables, height } = props;
  const [localTimeRange, setLocalTimeRange] = useState(timeRange);
  const [isRealTime, setIsRealTime] = useState(false);
  const [isPrediction, setIsPrediction] = useState(false);
  const [lastDevice, setLastDevice] = useState("0");
  const [lastPredictionParam, setLastPredictionParam] = useState("CO2");
  const [lastRawTime, setLastRawTime] = useState(timeRange.raw);

  const currentDevice = new URLSearchParams(window.location.search).get('var-device_id') ??
    replaceVariables("${device_id}") ?? "";
  const currentPredictionParam = replaceVariables("${prediction_sensor}");

  const onHistory = useCallback(() => {
    setIsRealTime(false);
    setIsPrediction(false);
    setLocalTimeRange(timeRange);
    getTimeSrv().setAutoRefresh(null);
    getLocationSrv().update({ query: { 'var-isRealTime': '0', 'var-isPrediction': '0' }, partial: true, replace: true });
    getTimeSrv().refreshTimeModel();
  }, [timeRange]);

  const calcRealTimeRange = () => {
    const timeSrv = getTimeSrv();
    const raw = { from: 'now-10m', to: 'now', };
    const timezone = timeSrv.timeModel ? timeSrv.timeModel.getTimezone() : undefined;
    return {
      from: dateMath.parse(raw.from, false, timezone, timeSrv.timeModel?.fiscalYearStartMonth)!,
      to: dateMath.parse(raw.to, true, timezone, timeSrv.timeModel?.fiscalYearStartMonth)!,
      raw: raw,
    };
  }

  const calcPredictionTimeRange = (timeRange: any) => {
    const diffFromNow = (moment.isMoment(timeRange.from)) ? moment().diff((timeRange.from as Moment)) : -5000;
    if (diffFromNow < 100) {
      return timeRange;
    }
    const futureTo = moment().add(diffFromNow);
    return {
      from: (timeRange.from as DateTime),
      to: (futureTo as DateTime),
      raw: timeRange.raw,
    };
  }

  const onRealTime = useCallback(() => {
    if (!isRealTime) {
      setIsRealTime(true);
      setIsPrediction(false);
      setLocalTimeRange(calcRealTimeRange());
      getTimeSrv().setAutoRefresh('1m');
      getLocationSrv().update({ query: { 'var-isRealTime': '1', 'var-isPrediction': '0' }, partial: true, replace: true });
      getTimeSrv().refreshTimeModel();
    }
  }, [isRealTime]);

  const onPrediction = useCallback(() => {
    if (config.showPredictionTab && !isPrediction) {
      console.log("config", config);
      setIsRealTime(false);
      setIsPrediction(true);
      setLocalTimeRange(calcPredictionTimeRange(timeRange));
      getTimeSrv().setAutoRefresh(null);
      getLocationSrv().update({ query: { 'var-isRealTime': '0', 'var-isPrediction': '1', }, partial: true, replace: true });
      getTimeSrv().refreshTimeModel();
    }
  }, [isPrediction, timeRange]);

  useEffect(() => {
    if (lastDevice !== currentDevice) {
      setLastDevice(currentDevice);
      onHistory();
    } else if (config.showPredictionTab && isPrediction && (lastPredictionParam !== currentPredictionParam)) {
      const parametersOpts: string[] = replaceVariables("${sensor_type:csv}").split(',');
      const itemInOptions = parametersOpts.find((item) => item === currentPredictionParam);
      const result = (itemInOptions) ? itemInOptions : ((parametersOpts?.length > 0) ? parametersOpts[0] : "");
      setLastPredictionParam(result);
    }

  }, [lastDevice, onHistory, currentDevice, isPrediction, lastPredictionParam, currentPredictionParam, replaceVariables]);

  const isTheSameRawTime = (first?: any, second?: any): boolean => {
    let isTheSame = false;
    if (first?.from === second?.from && first?.to === second?.to) {
      isTheSame = true;
    } else {
      let bothAreMoments = moment.isMoment(first?.from) && moment.isMoment(second?.from)
      isTheSame = bothAreMoments &&
        (first?.from as Moment).isSame(second?.from as Moment) &&
        (first?.to as Moment).isSame(second?.to as Moment);

    }
    return isTheSame;
  }

  useEffect(() => {
    if (isRealTime && !isTheSameRawTime(timeRange?.raw, lastRawTime)) {
      setLastRawTime(timeRange.raw);
      onHistory();
    }

  }, [lastRawTime, onHistory, timeRange, isRealTime]);

  useEffect(() => {
    let changeTo = timeRange
    if (isRealTime) {
      changeTo = calcRealTimeRange();
    }
    else if (config.showPredictionTab && isPrediction) {
      changeTo = calcPredictionTimeRange(timeRange);
    }
    setLocalTimeRange(changeTo);
  }, [timeRange, isRealTime, isPrediction])

  const getSelectablePredictions = () => {
    const strArr: string[] = replaceVariables("${sensor_type:csv}").split(',');
    let res = new Array<SelectableValue<string>>(strArr.length);
    for (let i = 0; i < strArr.length; i++) {
      res[i] = { label: strArr[i], value: strArr[i] }
    }
    return res;
  }

  const changeSelectablePrediction = (value: SelectableValue<string>) => {
    if (lastPredictionParam !== value.value) {
      getLocationSrv().update({ query: { 'var-prediction_sensor': value.value, }, partial: true, replace: true });
      getTimeSrv().refreshTimeModel();
      console.log("config", config);
    }
  }

  const selectablePredictions = getSelectablePredictions();

  const renderTabBar = () => {
    return (
      <>
        <RadioButtonGroup
          value={config.showPredictionTab && isPrediction ? 'pt' : (isRealTime ? 'rl' : 'dt')}
          options={config.showPredictionTab ? [
            { label: 'History', value: 'dt' },
            { label: 'Real-Time', value: 'rl' },
            { label: 'Prediction', value: 'pt' },
          ] : [
            { label: 'History', value: 'dt' },
            { label: 'Real-Time', value: 'rl' },
          ]}
          onChange={(val: string) => {
            switch (val) {
              case 'dt':
                onHistory();
                break;
              case 'rl':
                onRealTime();
                break;
              case 'pt':
                onPrediction();
                break
            }
          }}
        />
      </>);
  }

  const renderPredictionSelection = () => {
    return (
      <>
        <div className={css`width: 150px; float:left; bolder: 1px solid green; margin:10px`}>
          <Select
            onChange={changeSelectablePrediction}
            options={selectablePredictions}
            value={lastPredictionParam}
          />
        </div>
        <div className={css`float:left; padding-top:15px; bolder: 1px solid red;`}>Parameter</div>
        {(data?.state === 'Loading') && <div className={css`float:left; padding-top:15px; padding-left:5px; color:#6699FF`}>
          loading...
          <img src="public/img/load.gif" alt="loading prediction" />
        </div>}
      </>);
  }

  return (
    <>
      <div className="myShape2">
        {renderTabBar()}
      </div>
      {config.showPredictionTab && isPrediction && renderPredictionSelection()}
      <TimeSeriesPanel {...props} 
        timeRange={localTimeRange}
        height={height - (config.showPredictionTab && isPrediction? 100 : 50)}>
      </TimeSeriesPanel>
    </>
  );
};
