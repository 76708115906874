import { HierarchyEntityTag } from "./hierarchy-folders";

export enum DeviceType {
  Indoor,
  Outdoor
}

export interface Device {
  id: string;
  name: string;
  orgId: number;
  parentId: string;
  companionId: string;
  serialNumber: string;
  type: DeviceType;
  locationAddress: string;
  locationGps: {
    lat: number;
    lng: number;
  };
  locationGeo: any;
  locationText: string;
  timezone: string;
  weekStart: string;
  description: string;
  properties: any;
  configRef: string;
  tags?: HierarchyEntityTag[];
  tagOptions?: HierarchyEntityTag[];
}

export interface DevicesState {
  devices: Device[];
  searchQuery: string;
  hasFetched: boolean;
}

export interface DeviceState {
  device: Device;
}
